.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background: linear-gradient(to right, #ffffff, #eaf2f8);
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: auto;
    text-align: center;
    gap: 2rem;
}

.about-info {
    max-width: 900px;
}

.about-info h3 {
    font-size: 1.6rem;
    line-height: 1.7;
    color: #2c3e50;
    margin: 0;
    font-weight: 500;
}

.about-image {
    display: flex;
    justify-content: center;
    width: 100%;
}

.about-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    border: 4px solid #e0e0e0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.about-content {
    text-indent: 50px;
    max-width: 900px;
    text-align: justify;
}

.about-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #4a4a4a;
    margin: 1rem 0;
}

.about-content p:first-of-type {
    margin-top: 0;
}

.about-content p:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .about-container {
        padding: 2rem;
    }

    .about-info h3 {
        font-size: 1.4rem;
    }

    .about-content p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .about-info h3 {
        font-size: 1.2rem;
    }

    .about-content p {
        font-size: 0.8rem;
    }
}
