@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.logo {
  display: flex;
  justify-content: center;
  background-color: #ebebeb;


}

.logo img {
  height: 70px;
}

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  height: 40vh;
  background: #0b3470;
  color: #fff;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 0 20px;
  border-radius: 10px;
}

h1 {
  cursor: pointer;
  font-size: 4rem;
  margin: 0;
  animation: fadeIn 1s ease-in-out;
}

.Typewriter {
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: blink 0.75s step-end infinite;
  z-index: 1;
}


@keyframes blink {
  0% {
    border-color: transparent;
  }

  50% {
    border-color: #FFD700;
  }

  100% {
    border-color: transparent;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}

.card {
  margin-top: 20px;
  position: relative;
  width: 290px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  background-color: white;
  z-index: +100;
  text-align: justify;
}

.card::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -0px;
  margin: auto;
  width: 290px;
  height: 294px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #0b3470 0%, #40c9ff 100%);
  z-index: -100;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 300px;
  height: 310px;
  inset: 0;
  background: #0b3470;
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.heading {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 700;
  border-bottom: 2px solid black;
  width: 35%;
}

.card p:not(.heading) {
  font-size: 18px;
}

.card p:last-child {
  color: white;
  font-weight: 600;
}

.card:hover::after {
  filter: blur(30px);
}

.card:hover::before {
  transform: rotate(-90deg) scaleX(1.2) scaleY(1.0);
}

.card-ai img {
  height: 300px;
  width: 300px;
  margin-left: -25px;
}

/* Navbar */

.Nav-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.navbar-bb {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 20px;

}

.nav-link {
  cursor: pointer;
}


.nav-link.active {
  background-color: #0b3470 !important;
  color: white !important;
  border-radius: 5px;
}

@media (max-width: 768px) {

  .card-ai {
    display: none;
  }
}

@media (max-width: 425px) {

  .card-container {
    margin: 20px 45px auto;

  }
}