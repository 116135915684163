 .project-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
  }
  
  .card-projects {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
  }
  

  .card-projects:hover {
    transform: translateY(-20px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: black;
  }
  
  .card-projects h3 {
    background: #f0f0f0;
    padding: 10px;
    font-size: 1.2rem;
    color: #0b3470;
    text-align: center;
    margin-bottom: 10px;
  }
  

  .card-projects p {
    text-align: justify ;
    padding: 0 15px 15px;
    font-size: 0.9rem;
    color: #555;
    line-height: 1.5;
  }
  
  .Heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #0b3470;
  }
  

  @media (max-width: 768px) {
    .project-container {
      justify-content: center;
    }
  
    .card-projects {
      max-width: 90%;
    }
  }
  