@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.contact-name {
    font-family: "Platypi", serif;
    margin-top: 30px;
    font-size: 40px;
}

.contact-details {
    font-family: "Platypi", serif;
    margin-top: 30px;
    font-size: 20px;
    color: rgb(117, 117, 117);
}

.form-container {
    background: linear-gradient(#0b3470, #40c9ff) padding-box,
        linear-gradient(145deg, transparent 10%, #0b3470, #40c9ff) border-box;
    border: 2px solid transparent;
    padding: 20px;
    font-size: 14px;
    font-family: inherit;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;
    border-radius: 16px;
    background-size: 100% 100%;
    animation: gradient 5s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.form-container button:active {
    scale: 0.95;
}

.form-container .form {
    display: flex;
    flex-direction: column;
    gap: 30px;      
}

.form-container .form-group {
    display: flex;
    flex-direction: column;
}

.form-container .form-group label {
    display: block;
    margin-bottom: 5px;
    color: black;
    font-weight: 600;
    font-size: 15px;
}

.form-container .form-group input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: #070000;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
}

.form-container .form-group textarea {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    resize: none;
    color: #000000;
    height: 96px;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
}

.form-container .form-group input::placeholder,
.custom-textarea::placeholder {
    opacity: 0.5;
    color: white;
}

.form-container .form-group input:focus,
.form-container .form-group textarea:focus {
    outline: none;
    border-color: #0b3470;
}

.form-container .form-submit-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: white;
    font-weight: 600;
    width: 50%;
    background: #0b3470;
    border: 1px solid #414141;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 10px;
}

.form-container .form-submit-btn:hover {
    background-color: #012c3d;
    border-color: #02132c;
    transition: .5s;
}

.contact-container,
.form-map-container {
    margin: 0 auto;
}

.contact-name,
.contact-details {
    text-align: center;
}

.form-content,
.map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-content {
    padding: 1rem;
}

.map-container {
    padding: 1rem;
}

.custom-textarea {
    width: 100%;
    max-width: 100%;
}

.map-iframe {
    width: 100%;
    height: 300px;
    border: 0;
}


@media (max-width: 768px) {

    .form-content,
    .map-container {
        margin-top: 10px;
        padding: 0;
    }

    .map-iframe {
        margin-top: 40px;
        margin-left: 10px;
        height: 200px;
    }

    .form-container .form{
        margin-top: 20px;
    }

    
}



.cards-title {
    color: #262626;
    font-size: 2em;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-desc {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    color: black;
    display: flex;
    justify-content: center;
    align-items: 'center';
    cursor: pointer;
}

.go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 2em;
    height: 2em;
    overflow: hidden;
    top: 0;
    right: 0;
    background: linear-gradient(135deg, #6293c8, #384c6c);
    border-radius: 0 4px 0 32px;
}

.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative; 
    height: 160px;  
    background-color: #f2f8f9;
    border-radius: 10px;
    padding: 1em;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
    font-family: Arial, Helvetica, sans-serif;
}

.cards:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: linear-gradient(135deg, #364a60, #384c6c);
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1.1);
    transform-origin: 50% 50%;
    transition: transform 0.35s ease-out;
}

.cards:hover:before {
    transform: scale(28);
}

.cards:hover .small-desc {
    transition: all 0.5s ease-out;
    color: rgba(255, 255, 255, 0.8);
}

.cards:hover .cards-title {
    transition: all 0.5s ease-out;
    color: #ffffff;
}

.last {
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    border-top: 1px solid gainsboro;
    margin-bottom: 1em;
}

.form-container .fb{
    font-size: 20px;
    text-align: center;
    color: white;
}

.form-map-container h1{
    font-size: 40px;
    text-align: center;
    margin-top: 30px;
}

a{
    text-decoration: none;
}